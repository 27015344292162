<template>
  <div class="RecCom-bg">
    <div class="RecCom-title">
      <p class="title1">{{$t('CompanyList.'+RecList[0].tit1)}}</p>
      <p>{{$t('CompanyList.'+RecList[0].tit2)}}</p>
    </div>
    <table class="Rec-list" width="80%" border="1px" cellspacing="0" cellpadding="1">
      <tr class="cnname-list" v-for="item in RecList" :key="item.id">
        <td width="5%">{{item.id}}</td>
        <td width="45%">
         <p>{{item.cnname}}</p><p>{{item.enname}}</p>
          </td>
        <td width="5%">{{item.id2}}</td>
        <td><p>{{item.cnname2}}</p><p>{{item.enname2}}</p></td>
      </tr>
    </table>
  </div>
</template>


<script>
export default {
  props:["RecList"],
  data () {
    return {
    }
  },
  

}
</script>

<style scoped lang="scss">
.RecCom-bg {
  height: 100%;
  .RecCom-title{
    margin: 30px;
    p{
      color: black;
    }
    
    .title1{
      display: inline;
      background-color: rgb(217, 217, 217);
    }
  }
  .Rec-list{
    margin-left: 10%;
    tr{
      height: 70px;
    }
    td{
        border: 2px solid rgb(97, 96, 96);
    }
    p{
      margin-top: 20px!important;
    }
  }
}

</style>