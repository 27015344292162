export default {
  data() {
    return {
      moreCompany: [
        {
          id: 1,
          tit1: 'complist_tit6',
          tit2: 'complist_tit6_1',
          cnname: '屈臣氏集團',
          enname: 'A.S. Watson Group',
          id2: 17,
          cnname2: '有線寬頻通訊有限公司',
          enname2: 'i-Cable Communications Limited',
        },
        {
          id: 2,
          cnname: '屈臣氏零售（香港）有限公司－豐澤',
          enname: 'A.S. Watson Retail (HK) Limited - FORTRESS',
          id2: 18,
          cnname2: '捷成洋行有限公司',
          enname2: 'Jebsen and Company Limited',
        },
        {
          id: 3,
          cnname: '屈臣氏零售（香港）有限公司－屈臣氏酒窖',
          enname: "A.S. Watson Retail (HK) Limited - Watson's Wine",
          id2: 19,
          cnname2: '美聯集團有限公司',
          enname2: 'Midland Holdings Limited',
        },
        {
          id: 4,
          cnname: '屈臣氏零售（香港）有限公司－屈臣氏',
          enname: "A.S. Watson Retail (HK) Limited - Watson's The Chemist",
          id2: 20,
          cnname2: '美聯工商舖有限公司',
          enname2: 'Midland IC&I Limited',
        },
        {
          id: 5,
          cnname: '',
          enname: 'ASM Pacific Technology Limited',
          id2: 21,
          cnname2: '現代貨箱碼頭有限公司',
          enname2: 'Modern Terminals Limited',
        },
        {
          id: 6,
          cnname: '貝克·麥堅時律師事務所',
          enname: 'Baker & Mckenzie',
          id2: 22,
          cnname2: '百佳超級市場（香港）有限公司',
          enname2: 'Parknshop (HK) Limited',
        },

        {
          id: 7,
          cnname: '滙秀企業有限公司',
          enname: 'Broadway-Nassau Investments Limited',
          id2: 23,
          cnname2: '電訊盈科有限公司',
          enname2: 'PCCW Limited',
        },
        {
          id: 8,
          cnname: '周大福珠寶集團有限公司',
          enname: 'Chow Tai Fook Jewellery Group Limited',
          id2: 24,
          cnname2: '陞域（控股）有限公司',
          enname2: 'Stan Group (Holdings) Limited',
        },
        {
          id: 9,
          cnname: '中華電力有限公司',
          enname: 'CLP Power Hong Kong Limited',
          id2: 25,
          cnname2: '東亞銀行有限公司',
          enname2: 'The Bank of East Asia, Limited',
        },
        {
          id: 10,
          cnname: '德意志銀行',
          enname: 'Deutsche Bank Aktiengesellschaft, Hong Kong Br.',
          id2: 26,
          cnname2: '香港上海滙豐銀行有限公司',
          enname2: 'The Hongkong and Shanghai Banking Corp Limited',
        },
        {
          id: 11,
          cnname: '富邦銀行（香港）',
          enname: 'Fubon Bank (Hong Kong) Limited',
          id2: 27,
          cnname2: '香港中華煤氣有限公司',
          enname2: 'The Hong Kong and China Gas Company Limited',
        },
        {
          id: 12,
          cnname: '馮氏（1937）管理有限公司',
          enname: 'Fung (1937) Management Limited',
          id2: 28,
          cnname2: '九龍巴士（一九三三）有限公司',
          enname2: 'The Kowloon Motor Bus Company (1933) Limited',
        },
        {
          id: 13,
          cnname: '恒生銀行有限公司',
          enname: 'Hang Seng Bank Limited',
          id2: 29,
          cnname2: '瑞銀集團',
          enname2: 'UBS AG',
        },
        {
          id: 14,
          cnname: '康業服務有限公司',
          enname: 'Hong Yip Service Company Limited',
          id2: 30,
          cnname2: '建華（業務）管理有限公司',
          enname2: 'Uni-China (Business) Management Limited',
        },
        {
          id: 15,
          cnname: '香港電訊有限公司',
          enname: 'HKT Limited',
          id2: 31,
          cnname2: '富城物業管理有限公司',
          enname2: 'Urban Property Management Limited',
        },
        {
          id: 16,
          cnname: '鴻福堂集團有限公司',
          enname: 'Hung Fook Tong Holdings Limited',
          id2: 32,
          cnname2: '偉邦物業管理有限公司',
          enname2: 'Well Born Real Estate Management Limited',
        },
      ],
      moreOrganisation: [
        {
          tit1: 'complist_tit7',
          tit2: 'complist_tit7_1',
          id: 1,
          cnname: '基督教靈實協會',
          enname: 'Haven of Hope Christian Service',
          id2: 3,
          cnname2: '香港恒生大學',
          enname2: 'The Hang Seng University of Hong Kong',
        },
        {
          id: 2,
          cnname: '香港房屋協會',
          enname: 'Hong Kong Housing Society',
          id2: 4,
          cnname2: '香港大學',
          enname2: 'The University of Hong Kong',
        },
      ],
      moreCompany2023: [
        {
          id: 1,
          tit1: 'complist_tit6',
          tit2: 'complist_tit6_1',
          cnname: '屈臣氏集團',
          enname: 'A.S. Watson Group',
          id2: 21,
          cnname2: '',
          enname2: 'Lenovo PCCW Solutions Limited',
        },
        {
          id: 2,
          cnname: '',
          enname: 'ASMPT Limited',
          id2: 22,
          cnname2: '領展資產管理有限公司',
          enname2: 'Link Asset Management Limited',
        },
        {
          id: 3,
          cnname: '中信銀行（國際）有限公司',
          enname: "China CITIC Bank International Limited",
          id2: 23,
          cnname2: '美聯物業（工商舖）有限公司',
          enname2: 'Midland Realty (Comm. & Ind.) Limited',
        },
        {
          id: 4,
          cnname: '中國移動香港有限公司',
          enname: "China Mobile Hong Kong Company Limited",
          id2: 24,
          cnname2: '美聯物業代理有限公司',
          enname2: 'Midland Realty International Limited',
        },
        {
          id: 5,
          cnname: '周大福珠寶集團有限公司',
          enname: 'Chow Tai Fook Jewellery Group Limited',
          id2: 25,
          cnname2: '現代貨箱碼頭有限公司',
          enname2: 'Modern Terminals Limited',
        },
        {
          id: 6,
          cnname: '中華電力有限公司',
          enname: 'CLP Power Hong Kong Limited',
          id2: 26,
          cnname2: '奧雅納工程顧問',
          enname2: 'Ove Arup & Partners HK Ltd',
        },

        {
          id: 7,
          cnname: '香港中文大學醫院',
          enname: 'CUHK Medical Centre',
          id2: 27,
          cnname2: '百佳超級市場',
          enname2: 'PARKnSHOP',
        },
        {
          id: 8,
          cnname: '大昌行集團有限公司',
          enname: 'Dah Chong Hong Holdings Limited',
          id2: 28,
          cnname2: '瑞安建業有限公司',
          enname2: 'SOCAM Development Limited',
        },
        {
          id: 9,
          cnname: '大新銀行有限公司',
          enname: 'Dah Sing Bank, Limited',
          id2: 29,
          cnname2: '香港太古可口可樂',
          enname2: 'Swire Coca-Cola HK',
        },
        {
          id: 10,
          cnname: '星展銀行（香港）有限公司',
          enname: 'DBS Bank (Hong Kong) Limited',
          id2: 30,
          cnname2: '太古資源有限公司',
          enname2: 'Swire Resources Limited',
        },
        {
          id: 11,
          cnname: '豐澤',
          enname: 'FORTRESS',
          id2: 31,
          cnname2: '東亞銀行有限公司',
          enname2: 'The Bank of East Asia, Limited',
        },
        {
          id: 12,
          cnname: '富邦銀行（香港）有限公司',
          enname: 'Fubon Bank (Hong Kong) Limited',
          id2: 32,
          cnname2: '香港中華煤氣有限公司',
          enname2: 'The Hong Kong and China Gas Company Limited',
        },
        {
          id: 13,
          cnname: '恒生銀行',
          enname: 'Hang Seng Bank',
          id2: 33,
          cnname2: '香港上海滙豐銀行',
          enname2: 'The Hongkong and Shanghai Banking Corporation Limited',
        },
        {
          id: 14,
          cnname: '香港航空有限公司',
          enname: 'Hong Kong Airlines Limited',
          id2: 34,
          cnname2: '九龍巴士（一九三三）有限公司',
          enname2: 'The Kowloon Motor Bus Company (1933) Limited',
        },
        {
          id: 15,
          cnname: '香港置業（地產代理）有限公司',
          enname: 'Hong Kong Property Services (Agency) Limited',
          id2: 35,
          cnname2: '瑞銀集團',
          enname2: 'UBS AG',
        },
        {
          id: 16,
          cnname: '康業服務有限公司',
          enname: 'Hong Yip Service Co. Limited',
          id2: 36,
          cnname2: '富城物業管理有限公司',
          enname2: 'Urban Property Management Limited',
        },
        {
          id: 17,
          cnname: '鴻福堂集團有限公司',
          enname: 'Hung Fook Tong Group Holdings Limited',
          id2: 37,
          cnname2: '屈臣氏酒窖',
          enname2: "Watson's Wine",
        },
        {
          id: 18,
          cnname: '有線寛頻通訊有限公司',
          enname: 'i-CABLE Communications Limited',
          id2: 38,
          cnname2: '香港屈臣氏',
          enname2: 'Watsons Hong Kong',
        },
        {
          id: 19,
          cnname: '捷成洋行有限公司',
          enname: 'Jebsen and Company Limited',
          id2: 39,
          cnname2: '偉邦物業管理有限公司',
          enname2: 'Well Born Real Estate Management Limited',
        },
        {
          id: 20,
          cnname: '仲量聯行物業管理有限公司',
          enname: 'Jones Lang LaSalle Management Services Limited',
        }
      ],
      moreOrganisation2023: [
        {
          tit1: 'complist_tit7',
          tit2: 'complist_tit7_1',
          id: 1,
          cnname: '基督教靈實協會',
          enname: 'Haven of Hope Christian Service',
          id2: 4,
          cnname2: '香港恒生大學',
          enname2: 'The Hang Seng University of Hong Kong',
        },
        {
          id: 2,
          cnname: '香港房屋協會',
          enname: 'Hong Kong Housing Society',
          id2: 5,
          cnname2: '香港大學',
          enname2: 'The University of Hong Kong',
        },
        {
          id: 3,
          cnname: '香港貿易發展局',
          enname: 'Hong Kong Trade Development Council',
        },
      ],
    }
  },
}
