export default {
  data () {
    return {
      betweenCompany: [
        {
          id: 1,
          tit1: 'complist_tit4',
          tit2: 'complist_tit4_1',
          cnname: '',
          enname: 'Adidas (adidas Sourcing Limited & adidas Hong Kong Limited)',
          id2: 16,
          cnname2: '國際物業管理有限公司',
          enname2: 'International Property Management Limited',
        },
        {
          id: 2,
          cnname: '730 媒體有限公司',
          enname: 'AM730 Media Limited',
          id2: 17,
          cnname2: '高明科技工程有限公司',
          enname2: 'KML Engineering Limited',
        },
        {
          id: 3,
          cnname: '亞洲保險有限公司',
          enname: 'Asia Insurance Company Limited',
          id2: 18,
          cnname2: '羅氏時裝集團有限公司',
          enname2: 'Laws Fashion Group Limited',
        },
        {
          id: 4,
          cnname: '澳至尊國際控股有限公司',
          enname: 'Ausupreme International Holdings Limited',
          id2: 19,
          cnname2: '思想（香港）有限公司',
          enname2: 'Master Concept (Hong Kong) Limited',
        },
        {
          id: 5,
          cnname: '百特醫療用品有限公司',
          enname: 'Baxter Healthcare Limited',
          id2: 20,
          cnname2: '',
          enname2: 'PAG Consulting Limited',
        },
        {
          id: 6,
          cnname: '藍十字（亞太）保險有限公司',
          enname: 'Blue Cross (Asia-Pacific) Insurance Limited',
          id2: 21,
          cnname2: '',
          enname2: 'PUMA Asia Pacific Limited',
        },
        {
          id: 7,
          cnname: '周大福企業有限公司',
          enname: 'Chow Tai Fook Enterprises Limited',
          id2: 22,
          cnname2: '信德集團有限公司',
          enname2: 'Shun Tak Holdings Limited',
        },
        {
          id: 8,
          cnname: '中信國際電訊（信息技術）有限公司',
          enname: 'CITIC Telecom International CPC Limited',
          id2: 23,
          cnname2: '',
          enname2: 'Snapask (Holdings) Inc.',
        },
        {
          id: 9,
          cnname: '嘉栢環球有限公司',
          enname: 'Crown Worldwide (HK) Limited',
          id2: 24,
          cnname2: '騰訊（香港辦公室）',
          enname2: 'Tencent (Hong Kong Office)',
        },
        {
          id: 10,
          cnname: '',
          enname: 'Decathlon Hong Kong Company Limited',
          id2: 25,
          cnname2: '特斯拉汽車香港有限公司',
          enname2: 'Tesla Motors HK Limited',
        },
        {
          id: 11,
          cnname: '溢達企業有限公司',
          enname: 'Esquel Enterprises Limited',
          id2: 26,
          cnname2: '捷榮集團（香港）有限公司',
          enname2: 'Tsit Wing Coffee Company Limited',
        },
        {
          id: 12,
          cnname: '',
          enname: 'Facebook Hong Kong Limited',
          id2: 27,
          cnname2: '聯合醫務集團有限公司',
          enname2: 'UMP Healthcare Holdings limited',
        },
        {
          id: 13,
          cnname: '富通保險有限公司',
          enname: 'FTLife Insurance Company Limited',
          id2: 28,
          cnname2: '',
          enname2: 'White & Case',
        },
        {
          id: 14,
          cnname: '葛蘭素史克有限公司',
          enname: 'GlaxoSmithKline Limited',
          id2: 29,
          cnname2: '葉氏化工集團有限公司',
          enname2: "Yip's Chemical Holdings Limited",
        },
        {
          id: 15,
          cnname: '霍金路偉律師行15',
          enname: 'Hogan Lovells',
        },
      ],
      betweenOrganisation: [
        {
          tit1: 'complist_tit5',
          tit2: 'complist_tit5_1',
          id: 1,
          cnname: '平等機會委員會',
          enname: 'Equal Opportunities Commission',
        },
      ],
      betweenCompany2023: [
        {
          id: 1,
          tit1: 'complist_tit4',
          tit2: 'complist_tit4_1',
          cnname: '屈臣氏實業',
          enname: 'A.S. Watson Industries',
          id2: 22,
          cnname2: '國際物業管理有限公司',
          enname2: 'International Property Management Limited',
        },
        {
          id: 2,
          cnname: '',
          enname: 'Adidas (adidas Sourcing Limited & adidas Hong Kong Limited)',
          id2: 23,
          cnname2: '高明科技工程有限公司',
          enname2: 'KML Engineering Limited',
        },
        {
          id: 3,
          cnname: '亞洲保險有限公司',
          enname: 'Asia Insurance Company Limited',
          id2: 24,
          cnname2: '高奧士國際有限公司',
          enname2: 'KOS International Limited',
        },
        {
          id: 4,
          cnname: '澳新銀行集團有限公司',
          enname: 'Australia and New Zealand Banking Group Limited',
          id2: 25,
          cnname2: '羅氏集團',
          enname2: 'LAWSGROUP',
        },
        {
          id: 5,
          cnname: '澳至尊國際控股有限公司',
          enname: 'Ausupreme International Holdings Limited',
          id2: 26,
          cnname2: '',
          enname2: 'Marsh McLennan',
        },
        {
          id: 6,
          cnname: '天職香港會計師事務所有限公司',
          enname: 'Baker Tilly Hong Kong Limited',
          id2: 27,
          cnname2: '美泰玩具亞太有限公司',
          enname2: 'Mattel Asia Pacific Sourcing Limited',
        },
        {
          id: 7,
          cnname: '滙秀企業有限公司',
          enname: 'Broadway-Nassau Investments Limited',
          id2: 28,
          cnname2: '',
          enname2: 'OKX HONG KONG FINTECH COMPANY LIMITED',
        },
        {
          id: 8,
          cnname: '駿建（香港）有限公司',
          enname: 'Builderprise (Hong Kong) Company Limited',
          id2: 29,
          cnname2: '樂思教育出版有限公司',
          enname2: 'Pan Lloyds Publishers Limited',
        },
        {
          id: 9,
          cnname: '香港中華游樂會',
          enname: 'Chinese Recreation Club, Hong Kong',
          id2: 30,
          cnname2: '菲利普莫里斯亞洲集團有限公司',
          enname2: 'Philip Morris Asia Limited',
        },
        {
          id: 10,
          cnname: '安達人壽保險香港有限公司',
          enname: 'Chubb Life Insurance Hong Kong Limited',
          id2: 31,
          cnname2: '',
          enname2: 'PizzaExpress Hong Kong Limited',
        },
        {
          id: 11,
          cnname: '中信國際電訊(信息技術)有限公司',
          enname: 'CITIC Telecom International CPC Limited',
          id2: 32,
          cnname2: '',
          enname2: 'PUMA Asia Pacific Limited',
        },
        {
          id: 12,
          cnname: '',
          enname: 'Decathlon Hong Kong Company Limited',
          id2: 33,
          cnname2: '施羅德投資管理（香港）有限公司',
          enname2: 'Schroder Investment Management (Hong Kong ) Limited',
        },
        {
          id: 13,
          cnname: '',
          enname: 'Lalamove',
          id2: 34,
          cnname2: '信德集團',
          enname2: 'Shun Tak Holdings',
        },
        {
          id: 14,
          cnname: '教育出版社有限公司',
          enname: 'Educational Publishing House Limited',
          id2: 35,
          cnname2: '德材建築工程有限公司',
          enname2: "Techoy Construction Company Limited",
        },
        {
          id: 15,
          cnname: '溢達集團有限公司',
          enname: 'Esquel Group Limited',
          id2: 36,
          cnname2: '特斯拉汽車有限公司',
          enname2: "Tesla Motors HK Limited",
        },
        {
          id: 16,
          cnname: '基滙資本',
          enname: 'Gaw Capital Asset Management (HK) Limited',
          id2: 37,
          cnname2: '聲白國際有限公司',
          enname2: "Tessellation International Limited",
        },
        {
          id: 17,
          cnname: '興勝創建控股有限公司',
          enname: 'Hanison Construction Holdings Limited',
          id2: 38,
          cnname2: '捷榮咖啡有限公司',
          enname2: "Tsit Wing Coffee Company Limited",
        },
        {
          id: 18,
          cnname: '夏利文物業管理有限公司',
          enname: 'Harriman Property Management Limited',
          id2: 39,
          cnname2: '聯合醫務集團',
          enname2: "UMP Healthcare Group",
        },
        {
          id: 19,
          cnname: '霍金路偉律師行',
          enname: 'Hogan Lovells',
          id2: 40,
          cnname2: '華生環境管理有限公司',
          enname2: "Watson Environmental Management Limited",
        },
        {
          id: 20,
          cnname: '香港足球會',
          enname: 'Hong Kong Football Club',
          id2: 41,
          cnname2: '',
          enname2: "White & Case",
        },
        {
          id: 21,
          cnname: '',
          enname: 'Hungry Minds Group Limited',
          id2: 42,
          cnname2: '葉氏化工集團有限公司',
          enname2: "YIP'S CHEMICAL HOLDINGS LIMITED",
        },
      ],
      betweenOrganisation2023: [
        {
          tit1: 'complist_tit5',
          tit2: 'complist_tit5_1',
          id: 1,
          cnname: '商界環保協會有限公司',
          enname: 'Business Environment Council Limited',
          id2: 3,
          cnname2: '香港樹仁大學',
          enname2: "Hong Kong Shue Yan University",
        },
        {
          id: 2,
          cnname: '平等機會委員會',
          enname: 'Equal Opportunities Commission',
        }
      ],
    }
  }
}

