export default {
  data() {
    return {
      fewerCompany: [
        {
          id: 1,
          tit1: 'complist_tit2',
          tit2: 'complist_tit2_1',
          cnname: '亞洲運動及體適能專業學院有限公司',
          enname: 'Asian Academy for Sports & Fitness Professionals Limited',
          id2: 16,
          cnname2: '',
          enname2: 'MIU Training Hub',
        },

        {
          id: 2,
          cnname: '轟炸機．運動有限公司',
          enname: 'Bomber Sports Company Limited',
          id2: 17,
          cnname2: '',
          enname2: 'Nana Gym Limited',
        },
        {
          id: 3,
          cnname: '滿紙煙雲文化產業有限公司',
          enname: 'Boundless Artists Collective Limited',
          id2: 18,
          cnname2: '',
          enname2: 'Ooosh Limited',
        },
        {
          id: 4,
          cnname: '童樂活動有限公司',
          enname: "Child's Smile Limited",
          id2: 19,
          cnname2: '愛瑪有限公司',
          enname2: 'Platform AI Limited',
        },
        {
          id: 5,
          cnname: '中國香港綜合搏撃運動總會有限公司',
          enname: 'China Hong Kong Combat Sports Federation Limited',
          id2: 20,
          cnname2: '薈益有限公司',
          enname2: 'Prestique Limited',
        },
        {
          id: 6,
          cnname: '普匯中金國際控股有限公司',
          enname: 'Chinlink International Holdings Limited',
          id2: 21,
          cnname2: '體育係有限公司',
          enname2: 'Social S Limited',
        },
        {
          id: 7,
          cnname: '',
          enname: 'DEL Fitness & Pilates',
          id2: 22,
          cnname2: '天來環保建材（香港）有限公司',
          enname2: 'Tailor Recycled Aggregates (HK) Limited',
        },
        {
          id: 8,
          cnname: '凱天企業有限公司',
          enname: 'Harvest Sky Enterprises Limited',
          id2: 23,
          cnname2: '得易健康有限公司',
          enname2: 'Take2 Health Limited',
        },
        {
          id: 9,
          cnname: '香港滾軸溜冰學校有限公司',
          enname: 'HK Roller Skating School Limited',
          id2: 24,
          cnname2: '',
          enname2: 'Terra Forma Limited',
        },
        {
          id: 10,
          cnname: '香港攀石訓練學會有限公司',
          enname: 'Just Climb Association Limited',
          id2: 25,
          cnname2: '天奧環保有限公司',
          enname2: 'Tiostone Environmental Limited',
        },
        {
          id: 11,
          cnname: '啟德體育園有限公司',
          enname: 'Kai Tak Sports Park Limited',
          id2: 26,
          cnname2: '通力技術服務有限公司',
          enname2: 'Turning Technical Services Limited',
        },
        {
          id: 12,
          cnname: '翺盟資本（香港）有限公司',
          enname: 'Kallimount Capital (Hong Kong) Limited',
          id2: 27,
          cnname2: '威士忌科技有限公司',
          enname2: 'Wistkey Limited',
        },
        {
          id: 13,
          cnname: '納比迪環保建材（香港）有限公司',
          enname: 'Laputa Eco-construction Material Co. Limited',
          id2: 28,
          cnname2: '榮．運動有限公司',
          enname2: 'Wunique Sports Company Limited',
        },
        {
          id: 14,
          cnname: '領英（香港）有限公司',
          enname: 'LinkedIn Hong Kong Limited',
          id2: 29,
          cnname2: '奕朗顧問有限公司',
          enname2: 'Yello Limited',
        },
        {
          id: 15,
          cnname: '',
          enname: 'LUFT Fitness and Pilates',
        },
      ],
      fewerOrganisation: [
        {
          tit1: 'complist_tit3',
          tit2: 'complist_tit3_1',
          id: 1,
          cnname: '善行體育基金有限公司',
          enname: 'Action Sports Foundation Limited',
          id2: 3,
          cnname2: '中國香港跳繩體育聯會有限公司',
          enname2: 'Hong Kong Rope Skipping Federation, China Limited',
        },
        {
          id: 2,
          cnname: '協同國際學校',
          enname: 'Concordia International School',
          id2: 4,
          cnname2: '香港社會創投慈善基金會有限公司',
          enname2: 'SVHK Foundation Limited',
        },
      ],
      fewerCompany2023: [
        {
          id: 1,
          tit1: 'complist_tit2',
          tit2: 'complist_tit2_1',
          cnname: '亞洲運動及體適能專業學院有限公司',
          enname: 'Asian Academy for Sports & Fitness Professionals Limited',
          id2: 21,
          cnname2: '',
          enname2: 'LANE EIGHT',
        },
        {
          id: 2,
          cnname: '百特醫療用品有限公司',
          enname: 'Baxter Healthcare Limited',
          id2: 22,
          cnname2: '納比迪環保建材有限公司',
          enname2: 'Laputa Eco-construction Material Co. Limited',
        },
        {
          id: 3,
          cnname: '翔龍集團（大中華區）有限公司',
          enname: 'BFL Group (Greater China) Limited',
          id2: 23,
          cnname2: '朗悅（亞洲）顧問有限公司',
          enname2: 'Long Yue (Asia) Advisory Limited',
        },
        {
          id: 4,
          cnname: '滿紙煙雲文化產業有限公司',
          enname: "Boundless Artists Collective Limited",
          id2: 24,
          cnname2: '',
          enname2: 'Marvelconnect Technology Limited',
        },
        {
          id: 5,
          cnname: '沛達管綫及專業測量有限公司',
          enname: 'Buda Surveying Limited',
          id2: 25,
          cnname2: '新澤管理有限公司',
          enname2: 'New Heritage Management Limited',
        },
        {
          id: 6,
          cnname: '金寶物業發展有限公司',
          enname: 'Campell Property Development Limited',
          id2: 26,
          cnname2: '一通運動工作坊有限公司',
          enname2: 'Onecallaway. Fitness Limited',
        },
        {
          id: 7,
          cnname: '心型創作有限公司',
          enname: 'Core Workshop Limited',
          id2: 27,
          cnname2: '先鋒體育發展有限公司',
          enname2: 'Pioneer Sports Development Limited',
        },
        {
          id: 8,
          cnname: '育才教育有限公司',
          enname: 'Edusmart Company Limited',
          id2: 28,
          cnname2: '寶麗化粧品（香港）有限公司',
          enname2: 'Pola Cosmetics (H.K.) Co., Limited',
        },
        {
          id: 9,
          cnname: '',
          enname: 'EPISODE Limited',
          id2: 29,
          cnname2: '',
          enname2: 'Pottinger 22 Limited',
        },
        {
          id: 10,
          cnname: '',
          enname: 'ESG Hong Kong Limited',
          id2: 30,
          cnname2: '薈益有限公司',
          enname2: 'Prestique Limited',
        },
        {
          id: 11,
          cnname: '膳動衡',
          enname: 'FOODSPORT',
          id2: 31,
          cnname2: '體育係有限公司',
          enname2: 'Social S Limited',
        },
        {
          id: 12,
          cnname: '爽物業有限公司',
          enname: 'H Properties Limited',
          id2: 32,
          cnname2: '新潔清潔服務',
          enname2: 'Sunkat Cleaning Service CO',
        },
        {
          id: 13,
          cnname: '凱天企業有限公司',
          enname: 'Harvest Sky Enterprises Limited',
          id2: 33,
          cnname2: '卓雄實業有限公司',
          enname2: 'Super Leader Industrial Limited',
        },
        {
          id: 14,
          cnname: '',
          enname: 'Healthilia Company Limited',
          id2: 34,
          cnname2: '天來環保建材（香港）有限公司',
          enname2: 'Tailor Recycled Aggregates (HK) Limited',
        },
        {
          id: 15,
          cnname: '香港康寶萊國際有限公司',
          enname: 'Herbalife International of HK Limited',
          id2: 35,
          cnname2: '天奧環保有限公司',
          enname2: 'Tiostone Environmental Limited',
        },
        {
          id: 16,
          cnname: '香港雙節棍總會有限公司',
          enname: 'HK Nunchaku Association Limited',
          id2: 36,
          cnname2: '天奥集團有限公司',
          enname2: 'Tiostone Holdings Limited',
        },
        {
          id: 17,
          cnname: '香港滾軸溜冰學校有限公司',
          enname: 'Hong Kong Roller Skating School Limited',
          id2: 37,
          cnname2: '通力技術服務有限公司',
          enname2: 'Turning Technical Services Limited',
        },
        {
          id: 18,
          cnname: '中國香港跳繩體育聯會有限公司',
          enname: 'Hong Kong Rope Skipping Federation, China Limited',
          id2: 38,
          cnname2: '',
          enname2: 'Verano Boxing Club Limited',
        },
        {
          id: 19,
          cnname: '啟德體育園',
          enname: 'Kai Tak Sports Park',
          id2: 39,
          cnname2: '',
          enname2: 'WEUNION Limited',
        },
        {
          id: 20,
          cnname: '翺盟資本（香港）有限公司',
          enname: 'Kallimount capital (HK) Ltd',
          id2: 40,
          cnname2: '一画廊',
          enname2: 'Yan Gallery',
        },
      ],
      fewerOrganisation2023: [
        {
          tit1: 'complist_tit3',
          tit2: 'complist_tit3_1',
          id: 1,
          cnname: '豐盛社企學會有限公司',
          enname: 'Fullness Social Enterprises Society Limited',
          id2: 5,
          cnname2: '滾動的書有限公司',
          enname2: 'Rolling Books Limited',
        },
        {
          id: 2,
          cnname: '',
          enname: 'Generation: You Employed (HK) Limited',
          id2: 6,
          cnname2: '全城街馬',
          enname2: 'RunOurCity Foundation Limited',
        },
        {
          id: 3,
          cnname: '香港公司治理公會',
          enname: 'The Hong Kong Chartered Governance Institute',
          id2: 7,
          cnname2: '',
          enname2: 'Time Auction Limited',
        },
        {
          id: 4,
          cnname: '香港棍網球基金有限公司',
          enname: 'HKLAX Foundation Limited',
        },
      ],
    }
  },
}
